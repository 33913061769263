import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://api.buttercms.com/v2/content/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
const apiKey = "auth_token=60ff148b955970a8f326d597e2f33d0e65cd5bc1";
export default {
  getContent(type){
    return apiClient.get(type+"?"+apiKey)
  },
  getContentFilter(type, subtype)
  {
    return apiClient.get(type+"?fields.categories="+subtype+"&"+apiKey)
  },
  getPhotos()
  {
    return apiClient.get("?keys=stock_photos&"+apiKey)
  },
  getContentFromKey(key)
  {
    return apiClient.get("?keys="+key+"&"+apiKey)
  }
}