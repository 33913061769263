<template>
  <div class="section background-thing">
      <div class="row row-split-content">
        <div class="content-width-small" style="text-align: center;">
          <h6 class="subheading text-primary-1">Facebook Ad of The Month</h6>
          <h3 class="h1">{{month}}</h3>
          <div style="text-align: center;">Follow this step-by-step guide on how to create a successful Facebook Ad. Each month there is a new ad with detailed instructions that include targeting, caption, and campaign techniques.</div>
          <a href="#" class="button w-inline-block today-download">
            <div>
              <a v-bind:href="ad.instructions" class="button w-inline-block post-download" target="_blank" download>
                <div>
                  Download Ad Instructions
                  <i class="fas fa-download"></i>
                </div>
              </a>
            </div>
          </a>
        </div>
        <div class="content-width-large creative">
          <div data-animation="slide" data-duration="500" data-infinite="1" class="slider w-slider">
            <div class="w-slider-mask">
              <div class="w-slide">
                <img v-bind:src="creative" width="512.5" />
              </div>
            </div>
          </div>
          <a v-bind:href="download" class="button w-inline-block post-download download-photo" download>
            <div>
              Download Photo
              <i class="fas fa-download"></i>
            </div>
          </a>
        </div>
      </div>
    </div>
</template>

<script>
import moment from "moment";
import ContentService from "@/services/contentService.js";
export default {
  data() {
    return {
      ads: [],
      creative: "",
      ad:{}
    };
  },
  props: {
    month: String,
    firstMonth: Number
  },
  computed: {
    download: function() {
      var media = this.creative;
      media = media.substring(26);
      var safeTitle = encodeURI("facebookAd.jpg");
      var url =
        "https://fs.buttercms.com/content=t:attachment,f:%22" +
        safeTitle +
        "%22/" +
        media;
      return url;
    }
  },
  created() {
    var vm = this;
    var month = this.month;
    ContentService.getContent("facebook_ad")
      .then(response => {
        console.log(response.data.data.facebook_ad);
        vm.ads = response.data.data.facebook_ad;
        vm.ads.forEach(function(ad) {
          if (moment(month).isSame(ad.month, "month")) {
            console.log("match");
            vm.ad = ad;
            vm.creative = ad.creative;
          }
        });
        console.log("my vm");
        console.log(vm.ad);
      })
      .catch(error => {
        console.log("There was an error getting blog posts", error);
      });
  },
  watch: {
    month: {
      immediate: true,
      handler(val) {
        var currentAd = null;
        this.ads.forEach(function(ad) {
          if (moment(val).isSame(ad.month, "month")) {
            currentAd = ad;
          }
        });
        if (currentAd) {
          this.ad = currentAd;
          this.creative = currentAd.creative;
        }
      }
    }
  }
};
</script>

<style scoped>
.creative{
  text-align: center;
}
.download-photo{
  margin-top: 15px;
}
.background-thing{
  background-color:#fafcff;
}
</style>