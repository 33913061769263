<template>
  <div class="home">
    <Navigation />
    <div class="section bg-primary-3">
      <div class="container text-center">
                <h1>
          <i v-on:click="reverse" class="fas fa-arrow-left arrow"></i>
          {{month}}
          <i v-on:click="advance" class="fas fa-arrow-right arrow"></i>
        </h1>
      </div>
    </div>
    <div class="section bg-gray-4">
        <Ad :month="month" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import moment from "moment";
import Ad from "@/components/FacebookAd.vue";


export default {
  name: "home",
  components: {
    Navigation,
    Ad
  },
  data() {
    return {
      currentDate: Date.now(),
      month: moment(Date.now()).format("MMMM YYYY")
    };
  },
  methods: {
    advance: function() {
      var futureMonth = moment(this.currentDate).add(1, "M");
      var formatedMonth = moment(futureMonth).format("MMMM YYYY");
      this.month = formatedMonth;
      this.currentDate = futureMonth;
    },
    reverse: function() {
      var bornOnDate = new Date(2019, 11, 31);
      var pastMonth = moment(this.currentDate).subtract(1, "M");
      if (bornOnDate <= pastMonth) {
        var formatedMonth = moment(pastMonth).format("MMMM YYYY");
        this.month = formatedMonth;
        this.currentDate = pastMonth;
      }
    }
  }
};
</script>
